import { useEffect } from 'react';

const useScript = (url, data = null) => {
  useEffect(() => {
    const script = document.createElement('script');

    if(!data) {
        script.src = url;
        script.async = true;
    } else {
        script.innerHTML = data;
    }

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, [url, data]);
};

export default useScript;