import styled from "styled-components";

export const AboutSec = styled.div`
  color: #ffff;
  padding: 60px 0;
  background: ${({ lightBg }) => (lightBg ? "#FFFF" : "#101522")};
`;

export const AboutRow = styled.div`
  display: flex;
  margin: 0 -15px -15px -15px;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: ${({ imgStart }) => (imgStart ? "row-reverse" : "row")};
`;

export const AboutColumn = styled.div`
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 50%;
  flex-basis: 50%;

  @media screen and (max-width: 768px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
  }
`;
export const AboutCard = styled.div`
  color: #1d6086;
  padding: 16px;
  display: flex;
  flex-direction: column;
  width: 33.33%;
  min-width: 152px;
  /* height: 220px; */
  h3 {
    margin-top: 16px;
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 24px;
  }
  p {
    margin-top: 16px;
    margin: auto;
    display: flex;
  }
  img {
    width: 65px;
  }
`;

export const AboutHolder = styled.div`
  /* max-width: 555px; */
  display: flex;
  align-items: flex-start;

  justify-content: space-between;
  flex-wrap: wrap;

  @media screen and (max-width: 700px) {
    padding-bottom: 20px;
    flex-wrap: wrap;
    /* overflow-x: auto ; */
    /* fire fox */
    /* scrollbar-width: thin; */
    /* other */
    /* &::-webkit-scrollbar {
      height: 2px;
    }
    &::-webkit-scrollbar-track {
      background-color: #ddd;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #000;
      border-radius: 10px;
    } */
  }
`;
export const TextWrapper = styled.div`
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;

  @media screen and (max-width: 768px) {
    padding-bottom: 65px;
  }
`;

export const TopLine = styled.div`
  color: ${({ lightTopLine }) => (lightTopLine ? "#1d6086" : "#1d6086")};
  font-size: 18px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  margin-bottom: 16px;
`;

export const Img = styled.img`
  padding-right: 0;
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  max-height: 500px;
`;

export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: ${({ lightText }) => (lightText ? "#1d6086" : "#1d6086")};
`;

export const Subtitle = styled.p`
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
  color: ${({ lightTextDesc }) => (lightTextDesc ? "#1d6086" : "#1c2237")};
`;
