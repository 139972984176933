import React from "react";
import Vimeo from "@u-wave/react-vimeo";
import styled from "styled-components";

export default function Videoss() {
  return (
    <>
      <VideoContainer>
        <Video
          background={false}
          height={80}
          loop={true}
          responsive
          video="529949088"
          width={20}
        />
      </VideoContainer>
      <Overlay />
    </>
  );
}

const VideoContainer = styled.div`
  bottom: 0;
  left: 100;
  height: 30%;
  width: 30%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  right: 0;
  top: 110;
  z-index: -1;
`;

const Video = styled((props) => <Vimeo {...props} />)`
  height: 56.25vw; // for a 16:9 aspect ratio, 9/16*100 = 56.25
  right: -310px;
  min-height: 50vh;
  width: 177.77vh; // for a 16:9 aspect ratio, 16/9*100 = 177.77
  position: absolute;
  top: 70%;
  transform: translate(-50%, -50%);
  width: 50vw;
`;

{
  /*
For a nice vintage and darkening effect
*/
}

const Overlay = styled.div`
  background-color: rgba(0, 0, 0, 0.66);
  bottom: 0;
  box-shadow: inset 0 0 5rem rgba(0, 0, 0, 0.5);
  left: 0;
  min-height: 50%;
  min-width: 50%;
  object-fit: cover;
  object-position: center;
  position: fixed;
  right: 0;
  top: 0;
  z-index: -1;
`;
