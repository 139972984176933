import App from "./App";
import FontStyles from "./fontStyles";
import { Helmet } from "react-helmet";
import React from "react";
import ReactDOM from "react-dom";

Helmet.canUseDOM = false;

ReactDOM.render(<App />, document.getElementById("root"));
const helmet = Helmet.renderStatic();
