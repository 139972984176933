import {
  BtnWrapper,
  MainBtnLink,
  MainSection,
  MainTitle,
  Para,
  SvgWrapper,
  SvgWrapper2,
} from "./MainPage.elements";
import { Button, Container } from "../../globalStyles";

import React from "react";

function MainPage({ primary, big, fontBig, Color, borderColor, Width }) {
  return (
    <>
      <MainSection>
        <Container id="drag_magnifier_holder">
          <SvgWrapper id="drag_magnifier">
            <div class="geral">
              <div class="lupa">
                <div class="lupa_detalhe1"></div>
                <div class="lupa_detalhe2"></div>
              </div>

              <div class="cabo_lupa">
                <div class="cabo_detalhe1"></div>
                <div class="cabo_detalhe2"></div>
              </div>

              <div class="cabo_lupa_down">
                <div class="cabo_lupa_down_detalhe1"></div>
                <div class="cabo_lupa_down_detalhe2"></div>
                <div class="cabo_lupa_down_detalhe3"></div>
                <div class="cabo_lupa_down_detalhe4"></div>
              </div>
            </div>
          </SvgWrapper>
          <MainTitle>
            <span
              data-aos="fade-down"
              data-aos-offset="200"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
            >
              We add to your product an identity
            </span>
          </MainTitle>
          <Para data-aos="fade-up" data-aos-duration="3000">
            We provide a seamles and safe product protection from the market
            fraudulence. Offering a variety of primary employees listings, with
            secure payment facilities, all from the comfort of your company.
          </Para>
          <BtnWrapper>
            <MainBtnLink to="#subscribe">
              <Button primary Color borderColor={"#000"}>
                Learn More
              </Button>
            </MainBtnLink>
          </BtnWrapper>
          {/* <img style={{width: "400px",transform: "translate(400px, 190px)"}}src={logo2} alt="Svg" loading="lazy" /> */}
          {/* <SvgWrapper2>
            <img className="imgMain" src={logo2} alt="Svg" loading="lazy" />
            <img className="imgRelative" src={logo1} alt="Svg" loading="lazy" />
          </SvgWrapper2> */}
        </Container>
      </MainSection>
    </>
  );
}

export default MainPage;
