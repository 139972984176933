import "./vcard.scss";

import {
  AboutCard,
  AboutColumn,
  AboutHolder,
  AboutRow,
  AboutSec,
  Heading,
  Img,
  NavBtnLink,
  Spanss,
  Subtitle,
  TextWrapper,
} from "./Virtual.elements";
import { Button, Container } from "../../globalStyles";
import React, { useState } from "react";
import {
  virtualCardInfo,
  virtualCardInfo1,
  virtualCardInfo2,
} from "../HomePage/Data";

import FeaturesVc from "../../components/VcFeatures/FeaturesVc";
import { InfoSection } from "../../components";
import { Link } from "react-router-dom";
import { LinkRouter } from "react-router-dom";
import Product from "../Product/product";
import Videoss from "./Videoss";
import logo from "../../images/VCard.gif";
import logo2 from "../../images/VCard.gif";
import logo3 from "../../images/Untitled.png";
import vcard from "../../images/vcardpng.png";
// import { Link } from 'react-router-dom';

const VirtualCard = ({
  lightBg,
  lightText,
  lightTextDesc,
  imgStart,
  buttonLabel,
  btnColor,
}) => {
  const [value, setValue] = useState("Hello World");
  const handleChange = (e) => setValue(e.target.value);

  return (
    <>
    

      <AboutSec lightBg={!lightBg} data-aos="fade-up" data-aos-duration="400">
        <Container>
          <FeaturesVc />
          <AboutRow imgStart={imgStart}>
            <AboutColumn>
              <TextWrapper>
                {/* <TopLine data-aos="fade-right" data-aos-duration="2400" lightTopLine={lightTopLine}></TopLine> */}
                <Heading
                  data-aos="fade-right"
                  data-aos-duration="2400"
                  lightText={lightText}
                >
                  Bring Your Ideas To Life In No Time
                  {/* <div>
                    <input type="text" value={value} onChange={handleChange} />
                    <p>{value}</p>
                  </div> */}
                </Heading>
                <Subtitle
                  data-aos="fade-right"
                  data-aos-duration="2400"
                  lightTextDesc={lightTextDesc}
                >As a comprehensive solution for online ventures, TIX provides you with two essential features: an E-commerce Builder and a Website Builder.{" "}
                </Subtitle>
                <Subtitle
                  data-aos="fade-right"
                  data-aos-duration="2400"
                  lightTextDesc={lightTextDesc}
                >
                 With the E-commerce Builder, you can easily set up your online store, complete with user-friendly customization options and secure transaction capabilities. Meanwhile, the Website Builder simplifies website creation, offering mobile-responsive designs and versatile customization tools. TIX ensures that your digital identity is fully realized, whether you're running an online business or crafting a personal website.{" "}
                </Subtitle>
                <Subtitle
                  data-aos="fade-right"
                  data-aos-duration="2400"
                  lightTextDesc={lightTextDesc}
                >
                Additionally, TIX's seamless integration with NFC identity cards elevates your networking and information sharing capabilities, making it a one-stop platform for all your online needs.
                </Subtitle>

                <Button
                  Color={true}
                  btnColor={"white"}
                  big
                  fontBig
                  primary={true}
                >
                  <a
                    href="https://vcboard.qrfds.com/register"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Get Started
                  </a>
                </Button>
              </TextWrapper>
            </AboutColumn>
            <AboutColumn>
              <AboutHolder>
                <AboutCard data-aos="fade-left" data-aos-duration="1500">
                  <Img src={logo3} alt="icon" />
                  <h3>Build Your Ecommerce</h3>
                  <p>Sign-Up and Create your own account.</p>
                </AboutCard>
                <AboutCard data-aos="fade-left" data-aos-duration="1000">
                  <Img src={vcard} alt="icon" />
                  <h3>Generate your website</h3>
                  <p>Choose from a variety of professionally designed templates or create your own unique card layout. Tailor your digital identity to match your style..</p>
                </AboutCard>
                <AboutCard data-aos="fade-left" data-aos-duration="500">
                  <Img src={vcard} alt="icon" />
                  <h3>Elevate your networking</h3>
                  <p>Add all of your personal & proffesional socials.</p>
                </AboutCard>
              
              </AboutHolder>
            </AboutColumn>
          </AboutRow>

          <InfoSection {...virtualCardInfo} />
          <InfoSection {...virtualCardInfo1} />
          <InfoSection {...virtualCardInfo2} />
        </Container>
      </AboutSec>
    </>
  );
};

export default VirtualCard;
