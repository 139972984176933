import React from 'react';
import './Form.css';
import { Container} from '../../globalStyles';
import {
  AboutSec,
  AboutRow,
  AboutColumn,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  Form,
  FormInput
} from './PDF.elements';

const FormSuccess = (
  lightBg,
  lightTopLine,
  lightText,
  lightTextDesc,
  imgStart,
) => {
  return (
     <AboutSec data-aos="fade-up" data-aos-duration="2400" lightBg={lightBg}>

        <Container>
          <AboutRow data-aos="fade-up" data-aos-duration="2400" imgStart={!imgStart}>
            <AboutColumn>

              <TextWrapper>

                <TopLine lightTopLine={lightTopLine}>Congratulations
                </TopLine>
                <Heading lightText={!lightText}>The presentation has been sent to your mail</Heading>
                {/* <Subtitle lightTextDesc={!lightTextDesc}>Reach a wider audience, customize your dedicated landing page, and take advantage of our personalization process and marketing growth initiatives.  </Subtitle> */}
          

                {/* <Button big fontBig primary={primary}>
                    {buttonLabel}
                  </Button> */}
              </TextWrapper>
            </AboutColumn>
          </AboutRow>
        </Container>
      </AboutSec>
    
  );
}

export default FormSuccess;