import { Container } from "../../globalStyles";
import { FaMagento } from "react-icons/fa";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const Nav = styled.nav`
  background: #ffff 0% 0% no-repeat padding-box;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
  margin-top: 24px;
`;

export const NavbarContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  height: 80px;
  font-weight: bold;
  font-size: 16px;
  /* font: normal bold Gilroy ☞; */
  // padding : 0;

  ${Container}
`;

export const NavLogo = styled(Link)`
  color: #fff;
  justify-self: flex-start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  width: 100px;
  img {
    width: 50px;
  }
`;

export const NavIcon = styled(FaMagento)`
  margin-right: 0.5rem;
`;

export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 960px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  /* padding-left: 250px; */

  .res {
    display: none;
  }
  &.no_res {
    display: block;
  }

  @media screen and (max-width: 960px) {
    display: flex;
    margin: auto;
    flex-direction: column;
    width: 100%;
    height: 65vh;
    position: absolute;
    top: 80px;
    left: ${({ click }) => (click ? 0 : "-100%")};
    opacity: 1;
    transition: all 0.5s ease;
    background: #fff;

    .res {
      display: block;
    }
    &.no_res {
      display: none;
    }
  }
`;

export const NavItem = styled.li`
  height: 80px;
  border-bottom: 2px solid transparent;
  align-items: center;
  margin: auto;

  &:hover {
    color: #1d6086;
    /* border-bottom: 2px solid #4b59f7; */
    opacity: 1;
  }

  @media screen and (max-width: 960px) {
    width: 100%;

    &:hover {
      border: none;
    }
  }
`;

export const NavItemBtn = styled.li`
  @media screen and (max-width: 960px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 120px;
  }
`;

export const NavLinks = styled(Link)`
  color: #000;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 2rem;
  height: 100%;
  left: 80px;
  margin: auto;

  &:hover {
    color: #1d6086;
    /* border-bottom: 2px solid #4b59f7; */
    opacity: 1;
  }

  @media screen and (max-width: 960px) {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
    color: #000;
    &:hover {
      color: #1d6086;
      transition: all 0.3s ease;
    }
  }
`;

export const NavBtnLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 8px 8px;
  /* padding-left: 200px; */
  height: 100%;
  width: 100%;
`;
