import React, { useState } from 'react';
// import './Form.css';
import PDF from './PDF';
import FormSuccess from './FormSuccess';
import './Form.css';

const Form = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  function submitForm() {
    setIsSubmitted(true);
  }
  return (
    <>
      <div>
    {
       !isSubmitted ? (<PDF submitForm={ submitForm} />) : (<FormSuccess /> )
   }
      </div>
    </>
  );
};

export default Form;
