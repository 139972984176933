import {
  FooterContainer,
  FooterLinkTitle,
  SocialIcon,
  SocialIconLink,
  SocialIcons,
  SocialLogo,
  SocialMedia,
  SocialMediaWrap,
  WebsiteRights,
} from "./Footer.elements";

import React from "react";
import logo from "../../images/QH.png";

// import { Button } from '../../globalStyles';
// import {
//   FaFacebook,
//   FaInstagram,
//   FaYoutube,
//   FaTwitter,
//   FaLinkedin
// } from 'react-icons/fa';

function Footer() {
  return (
    <FooterContainer>
      <SocialMedia>
        <SocialMediaWrap>
          <SocialLogo href="/" target="_blank" aria-label="Home">
            <SocialIcon>
              <img src={logo} alt="Svg" width={"200px"} />
              <p>Products get identity</p>
            </SocialIcon>
          </SocialLogo>
          <SocialIcons>
            <SocialIconLink to="/shop">
              {/* <FaFacebook /> */}
              <FooterLinkTitle>Home</FooterLinkTitle>
            </SocialIconLink>
            <SocialIconLink to="#FAQ">
              {/* <FaYoutube /> */}
              <FooterLinkTitle>FAQs</FooterLinkTitle>
            </SocialIconLink>
            <SocialIconLink to="#about">
              {/* <FaTwitter /> */}
              <FooterLinkTitle>About</FooterLinkTitle>
            </SocialIconLink>
            <SocialIconLink to="#contactus">
              {/* <FaLinkedin /> */}
              <FooterLinkTitle>Contact</FooterLinkTitle>
            </SocialIconLink>
          </SocialIcons>
        </SocialMediaWrap>

        <WebsiteRights>All Copy Rights Reserved @2022</WebsiteRights>
      </SocialMedia>
    </FooterContainer>
  );
}

export default Footer;
