import React from 'react';

import { Container } from '../../globalStyles';
import {
  AboutSec,
  AboutRow,
  AboutFlexRow,
  AboutColumn,
  TextWrapper,
  Question,
  Heading,
  
  // Form,
  // FormInput
} from './FrequentlyAQ.elements';
import Accordion from '../../components/FaqAccordion'

function FrequentlyAQ({
  primary,
  lightBg,
  // topLine,
  // lightTopLine,
  lightText,
  lightTextDesc,
  // headline,
  // description,
  // buttonLabel,
  // img,
  // alt,
  imgStart,

}) {
  return (
    <>

      <AboutSec lightBg={lightBg}>
        <Container>
          <AboutRow imgStart={imgStart}>
            <AboutColumn>
              <TextWrapper>
                <Heading data-aos="fade-down" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600"
                  lightText={lightText}><span>Frequently </span> Asked Questions</Heading>
                {/* <Subtitle data-aos="fade-down" data-aos-offset="200" data-aos-easing="ease-in-sine"
                  lightTextDesc={lightTextDesc}>Chocolate bar cake bear claw candy canes chocolate cake candy candy. Toffee chupa chups sesame snaps lollipop halvah gingerbread. Fruitcake cotton candy shortbread pastry gummi bears pie macaroon sesame snaps. Oat cake halvah pudding cake sweet lemon drops.
                   </Subtitle> */}
              </TextWrapper>
            </AboutColumn>

            <AboutFlexRow>
              <Question data-aos="fade-down"
                data-aos-easing="linear"
                data-aos-duration="1500">
                <Accordion />
              </Question>
            </AboutFlexRow>
          </AboutRow>
        </Container>
      </AboutSec>

    </>
  );
}

export default FrequentlyAQ;
