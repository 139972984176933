import CircularMed from "./fonts/CircularStd-Medium.woff2";
import CircularStd from "./fonts/CircularStd-Black.woff2";
import CircularStd2 from "./fonts/CircularStd-Black.woff2";
import { createGlobalStyle } from "styled-components";

const FontStyles = createGlobalStyle`

 @font-face {
        font-family:  "Circular";
        src: local('Circular'), local('Circular'),
        url(${CircularStd}) format('woff2'),
        url(${CircularStd2}) format('woff2');
        font-weight: 900;
        font-style: normal;
    }

    @font-face {
        font-family:  "CircularMed";
        src: local('CircularMed'), 
        url(${CircularMed}) format('woff2'),
        font-weight: 500;
        font-style: normal;
    }
`;

export default FontStyles;
