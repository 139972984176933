import styled from "styled-components";

export const InfoSec = styled.div`
  color: #fff;
  padding: 120px 0;
  background: ${({ lightBg }) => (lightBg ? "#FFFF" : "#101522")};
  @media screen and (max-width: 768px) {
    padding: 80px 0;
  }
`;

export const InfoRow = styled.div`
  display: flex;
  margin: 0 -15px -15px -15px;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: ${({ imgStart }) => (imgStart ? "row-reverse" : "row")};
`;

export const InfoColumn = styled.div`
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 50%;
  flex-basis: 50%;

  @media screen and (max-width: 768px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
  }
`;

export const TextWrapper = styled.div`
  max-width: 540px;
  padding-top: 0;
  // padding-bottom: 60px;

  img {
    display: ${({ disp_icons }) => (disp_icons ? "flex" : "none")};
    /* width:  ${({ Width }) => (Width ? "428px" : "846px")};  */
    max-width: ${({ Width, chatbot }) =>
      chatbot ? chatbot : Width ? "428px" : "846px"};
    width: ${({ chatbot }) => (!chatbot ? "auto" : "150%")};
    position: relative;
    margin-top: 42px;
    /* margin-left: ${({ Width, chatbotM }) =>
      chatbotM ? chatbotM : Width ? "200px" : "60px"}; */

    right: ${({ chatbot }) => (chatbot ? "auto" : "50%")};
    transform: ${({ chatbot }) => (chatbot ? "auto" : "translateX(50%)")};
    position: ${({ chatbot }) => (chatbot ? "relative" : "absolute")};
    z-index: 1;

    @media screen and (max-width: 900px) {
      right: auto;
      transform: none;
      position: relative;
      width: ${({ chatbot }) => (!chatbot ? "100%" : "75%")};
    }
  }

  @media screen and (max-width: 768px) {
    padding-bottom: 15px;
  }
`;

export const ImgWrapper = styled.div`
  max-width: 555px;
  display: flex;
  justify-content: space-evenly;
  /* justify-content: ${({ start }) =>
    start ? "space-between" : "flex-end"}; */
  position: relative;

  .chatExtra1,
  .chatExtra2,
  .chatExtra3,
  .chatExtra4,
  .chatExtra5,
  .chatExtra6,
  .chatExtra7,
  .chatExtra8 {
    position: absolute;
  }

  .chatExtra1 {
    left: 0;
    bottom: calc(50% - 87px);
    width: 35%;
  }
  .chatExtra2 {
    right: 0;
    bottom: 50px;
    width: 35%;
  }
  .chatExtra3 {
    right: -10px;
    top: 20px;
    width: 40%;
  }
  .chatExtra4 {
    left: -10px;
    bottom: calc(50% + 70px);
    width: 40%;
  }
  .chatExtra5 {
    //Free
    left: 12px;
    top: -150px;
    width: 25%;
    z-index: 0;
    @media screen and (max-width: 768px) {
      position: relative;
      left: 85px;
      top: -100px;
      width: 25%;
      z-index: 0;
      display: none;
    }
  }
  .chatExtra6 {
    //Gold
    right: 120px;
    top: -230px;
    width: 40%;
    z-index: 3;
    @media screen and (max-width: 768px) {
      position: relative;
      left: 88px;
      top: -100px;
      width: 40%;
      z-index: 0;
      display: none;
    }
  }
  .chatExtra7 {
    //Diamond
    right: 320px;
    top: -150px;
    width: 25%;
    z-index: 2;
    @media screen and (max-width: 768px) {
      /* position: relative;
      left: -100px;
      top: -100px;
      width: 10%;
      z-index: 0; */
      display: none;
    }
  }
  .chatExtra8 {
    //user info
    right: 150px;
    top: -60px;
    width: 85%;
    z-index: 1;
  }
`;

export const TopLine = styled.div`
  color: ${({ lightTopLine }) => (lightTopLine ? "#a9b3c1" : "#4B59F7")};
  font-size: 18px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  margin-bottom: 16px;
`;

export const Img = styled.img`
  padding-right: 0;
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: ${({ Compare }) => (Compare ? "none" : "inline-block")};
  /* max-height: 700px; //For Search for Unit Section */
  object-fit: cover;
  width: ${({ WEDTH }) => (WEDTH ? WEDTH : "100%")};
  border-radius:  ${({ WEDTH }) => (WEDTH ? "10% 0 0 10%" : "0 10% 10% 0")}; ;

  @media screen and (max-width: 768px) {
    width: ${({ mWEDTH }) => (mWEDTH ? mWEDTH : "100%")};
    max-width: 150%;
  }
`;

export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: ${({ lightText }) => (lightText ? "#1d6086" : "#000")};
  span {
    color: #1d6086;
  }
`;

export const Subtitle = styled.p`
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
  color: ${({ lightTextDesc }) => (lightTextDesc ? "#a9b3c1" : "#1c2237")};
`;

export const AboutHolder = styled.div`
  /* max-width: 555px; */
  display: ${({ disp_socials }) => (disp_socials ? "flex" : "none")};
  /* align-items: flex-end; */
  /* justify-content:space-between; */
  /* flex-wrap: wrap; */
  position: absolute;
  right: 60px;
  transform: translate(-100%, 100%);
  width: 22.5%;
  overflow-x: hidden;
  @media screen and (max-width: 768px) {
    /* padding-bottom: 100px; */
    flex-wrap: nowrap;
    /* bottom: 0px; */
    overflow-x: hidden;
    transform: translate(-30%, -50%);
    width: 50%;
  }
`;

export const AboutCard = styled.div`
  color: #000;
  padding: 4px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  /* width: 33.33%; */
  /* min-width: 152px ; */
  /* height: 220px; */

  img {
    width: 65px;
  }
`;
