import { Link } from "react-router-dom";
import styled from "styled-components";

export const MainSection = styled.div`
  color: #a1b3e2;
  padding: 90px 0;
  background: #ffff;
`;

export const MainTitle = styled.h2`
  display: flex;
  position: relative;
  color: #000;
  font-size: 60px;
  font-weight: bold;
  /* font-family: Circular Std Black, Regular; */
  margin-top: 10px;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 84%;

  margin: auto;
  margin-bottom: 24px;
  padding: 10px;
  line-height: 1.1;

  span {
    position: relative;
  }
  @media only screen and (max-width: 700px) {
    font-size: 30px;
    font-weight: bolder;
    width: 100%;
    align-items: center;
    max-width: none;
  }
`;

export const Para = styled.p`
  position: relative;
  display: flex;
  margin: 20px 10%;
  padding: 20px;
  border: 0;
  max-width: 45%;
  margin: auto;
  /* vertical-align: baseline; */
  color: black;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* font-family:'Lato', sans-serif;  */
  opacity: 0.54;
  text-align: center;
  @media only screen and (max-width: 700px) {
    width: 100%;
    align-items: center;
    max-width: none;
  }
`;

export const SvgWrapper = styled.div`
  top: -45px;
  right: 10px;
  width: 400px;
  position: absolute;

  .lupa {
    position: absolute;
    width: 200px;
    height: 200px;
    background: rgba(0, 0, 0, 0.4); /*Transparencia*/
    border-radius: 100%;
    border-color: black;
    border-style: solid;
    border-width: 10px;
    // margin-left: 360px;
    // margin-top: 80px;
  }
  .lupa_detalhe1 {
    position: absolute;
    margin-top: 50px;
    margin-left: 20px;
    width: 80px;
    height: 5px;
    background: rgba(0, 0, 0, 0.4); /*Transparencia*/
    transform: rotate(150deg);
    border-radius: 100%;
  }
  .lupa_detalhe2 {
    position: absolute;
    margin-top: 70px;
    margin-left: 25px;
    width: 60px;
    height: 5px;
    background: rgba(0, 0, 0, 0.4); /*Transparencia*/
    transform: rotate(150deg);
    border-radius: 100%;
  }
  .cabo_lupa {
    position: absolute;
    width: 35px;
    height: 50px;
    background: black;
    top: 160px;
    left: 170px;
    transform: rotate(136deg);
  }
  .cabo_detalhe1 {
    position: absolute;
    width: 4px;
    height: 40px;
    background: white;
    margin-top: 5px;
    margin-left: 4px;
    border-radius: 20%;
  }

  .cabo_detalhe2 {
    position: absolute;
    width: 10px;
    height: 40px;
    background: white;
    margin-top: 5px;
    margin-left: 20px;
    border-radius: 10%;
  }
  .cabo_lupa_down_detalhe1 {
    width: 15px;
    height: 100px;
    background: white;
    margin-top: 30px;
    margin-left: 18px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .cabo_lupa_down_detalhe3 {
    position: absolute;
    width: 50px;
    height: 15px;
    background: black;
    margin-top: -133px;
    margin-left: -8px;
    border-radius: 100%;
  }
  .cabo_lupa_down_detalhe4 {
    position: absolute;
    width: 35px;
    height: 4px;
    background: white;
    margin-top: -120px;
    border-top-left-radius: 80%;
    border-top-right-radius: 80%;
  }
  .cabo_lupa_down {
    top: 180px;
    left: 241px;
    position: absolute;
    background: black;
    width: 35px;
    height: 150px;
    border-bottom-left-radius: 30%;
    border-bottom-right-radius: 30%;
    transform: rotate(315deg);
  }

  .geral {
    cursor: move;
    cursor: grab;
    cursor: grabbing;

    position: relative;
  }

  @media only screen and (max-width: 700px) {
    display: none;
    top: -20px;
    width: 50%;
    left: calc(18% - 50px);
    .lupa {
      position: absolute;
      width: 100px;
      height: 100px;
      background: rgba(0, 0, 0, 0.4);
      border-radius: 110%;
      border-color: black;
      border-style: solid;
      border-width: 7px;
      margin-left: 376px;
      margin-top: 0px;
    }
    /* transform: translate(-50%, -50%);  */
    /* left: -180px; */
  }
`;
export const SvgWrapper2 = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 0;

  .imgMain {
    width: 100%;
    max-width: 400px;
  }
  .imgRelative {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    width: 80%;
    max-width: 250px;
  }

  @media only screen and (max-width: 700px) {
    /* width: 10px; */
  }
`;
export const BtnWrapper = styled.div`
  width: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 20px;

  /* @media only screen and (max-width: 700px) {
        width: 150px;
        position: relative;
        margin: auto;
        margin-top: 20px;

        } */
`;

// export const MainBtnLink = styled(Link)`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   text-decoration: none;
//   padding: 8px 16px;
//   /* padding-left: 200px; */
//   height: 100%;
//   width: 100%;
// `;

export const MainBtnLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 8px 16px;
  height: 100%;
  width: 100%;
`;
//--------------------------------------------------------

export const SwipeText = styled.div`
  *,
  *::before,
  *::after {
    padding: 0;
    margin: 0 auto;
    box-sizing: border-box;
  }

  body {
    background-color: black;
    height: 100vh;
    display: grid;
    grid-template: 100% / 3fr repeat(54, 1fr) 3fr;
    overflow: hidden;
  }

  .cell {
    width: 100%;
    height: 100%;
    // border: 1px solid #fff1;
    z-index: 2;
  }

  @for $i from 0 to 56 {
    .cell:nth-child(#{$i + 1}):hover ~ .content {
      --partialString: "#{str-slice("Loremipsumdolorsitametconsecteturadipisicingelit.", 1, $i + 1)}";
      --size: 32px;
      --color: #fff;
    }
  }

  .content {
    --partialString: "Hover over the screen to reveal the text...";
    --color: #333;
    --size: 16px;

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    // justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1em;
  }

  .text:after {
    content: var(--partialString);
    font-family: "Fredoka One", cursive;
    font-size: var(--size);
    color: var(--color);
  }
`;
