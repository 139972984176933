// import React, { useRef } from 'react';

import { FaAccessibleIcon } from 'react-icons/fa';
import emailjs from 'emailjs-com';

export const sendMail = (e, to_name, email, phone) => {
    // e.preventDefault();
    e.stopPropagation();
    // company_name
    // console.log(e);
    
    
    const templateParams = {
        email,
        phone,
        to_name, 
        // message : company_name,
    };
   
    //might be user_sW4Jnb4h5Q4J6lpDK
     
    return emailjs.send("service_jyo54z7", "template_hw2fk2k", templateParams, "sW4Jnb4h5Q4J6lpDK")
        .then(function(response) {
           console.log('SUCCESS!', response.status, response.text);
           
        }, function(error) {
           console.log('FAILED...', error);
           
        });
};