import "../src/global.css";
import "aos/dist/aos.css";

import { Footer, Navbar } from "./components";
import React, { useEffect } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import AOS from "aos";
import GlobalStyle from "./globalStyles";
import { Helmet } from "react-helmet";
import Home from "./pages/HomePage/Home";
import ScrollToTop from "./components/ScrollToTop";
import Shop from "./pages/Shop/Shop";
import VirtualCard from "./pages/virtual-Card/VirtualCard";
import { init } from "emailjs-com";
import useScript from "./hooks/useScript";
import Pricing from "./pages/Pricing/pricing";

function App() {
  useScript("https://www.googletagmanager.com/gtag/js?id=G-L9XGGZS3TL");
  useScript(
    null,
    `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-L9XGGZS3TL');
  `
  );
  useEffect(() => {
    AOS.init();
    init("user_XWNENXUTHnY9eVs75KrHC");

    window.addEventListener("scroll", (e) => {
      AOS.refresh();
    });
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>QRFD Solutions - QR Fraud Detection Solutions</title>
        <meta
          name="description"
          content="Software service that creates your website or ecommerce in seconds."
        />

        <meta name="author" content="QRFD Solutions" />
        <meta
          name="keywords"
          content="Create Website, Create your CV Portofolio, Build your BLogs ,Build your website, Build your E-commerce"
        />

        <meta name="robots" content="index, follow" />
        <link href="https://www.qrfds.com/" rel="canonical" />
        <meta name="og:type" property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://www.qrfds.com/static/media/qrfd.b544bb10.png"
        />
        <meta
          name="og:title"
          property="og:title"
          content="QRFDS - Web builder"
        />
        <meta
          name="og:description"
          property="og:description"
          content="ßSoftware service that creates your website or ecommerce in seconds."
        />
      </Helmet>
      <Router>
        <GlobalStyle />
        <ScrollToTop />
        <Navbar />
        <Switch>
          <Route path="/shop" component={Shop} />
          <Route path="/qrfd" component={Home} />
          <Route path="/pricing" component={Pricing} />
          <Route path="/" component={VirtualCard} />
        </Switch>

        <Footer />
      </Router>
    </>
  );
}

export default App;

// import "../src/global.css";
// import "aos/dist/aos.css";

// import { Footer, Navbar } from "./components";
// import React, { useEffect } from "react";
// import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

// import AOS from "aos";
// import GlobalStyle from "./globalStyles";
// import Home from "./pages/HomePage/Home";
// import ScrollToTop from "./components/ScrollToTop";
// import VirtualCard from "./pages/virtual-Card/VirtualCard";
// import { init } from "emailjs-com";

// function App() {
//   useEffect(() => {
//     AOS.init();
//     init("user_XWNENXUTHnY9eVs75KrHC");

//     window.addEventListener("scroll", (e) => {
//       AOS.refresh();
//     });
//   }, []);

//   return (
//     <Router>
//       <GlobalStyle />
//       <ScrollToTop />
//       <Navbar />
//       <Switch>
//         <Route path="/vcard" component={VirtualCard} />
//         <Route path="/" component={Home} />
//       </Switch>

//       <Footer />
//     </Router>
//   );
// }

// export default App;
