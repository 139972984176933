import "./products.css";

import { ProductSec, Root } from "./productElements";
import React, { useEffect, useState } from "react";

import { Container } from "../../globalStyles";
import logo from "../../images/fire-extinguisher.png";
import logo2 from "../../images/qrfd.png";
import logo3 from "../../images/oil.png";
import logo4 from "../../images/laundry.png";

const Product = () => {
  const logos = [logo, logo3, logo4];
  const [index, setIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((index) => (index + 1) % logos.length);
    }, 9_900);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <Container>
        <ProductSec>
          <img src={logos[index]} className="freeImage" alt="Eshtri logo" />
          <Root>
            <img src={logo2} alt="qrfd Logo" />
            <div class="iphone-x">
              <div class="bazzel">
                <div class="screen"></div>
              </div>
              <div class="line"></div>
              <div class="header">
                <div class="sensor-1"></div>
                <div class="sensor-2"></div>
                <div class="sensor-3"></div>
              </div>
              <div class="volume-button"></div>
              <div class="power-button"></div>
            </div>
          </Root>
        </ProductSec>
      </Container>
    </>
  );
};
export default Product;
