import { InfoSection, MainPage } from "../../components";
import {
  alerting,
  brandIdentity,
  customize,
  homeObj,
  homeObjFour,
  homeObjOne,
  // homeObjThree,
  // homeObjTwo,
} from "./Data";

import Aboutus from "./../Aboutus/Aboutus";
import Calendly from "../../components/Calendly/Calendly";
import FAQ from "../FrequentlyAQ/FrequentlyAQ";
import Form from "../PDF/Form";
import PDF from "../PDF/PDF";
import Product from "../Product/product";
import React from "react";

function Home() {
  return (
    <>
      <MainPage />
      <Aboutus />
      {/* <Calendly /> */}
      <Product />
      <InfoSection {...homeObj} />
      <InfoSection {...homeObjOne} />
      <InfoSection {...alerting} />
      <InfoSection {...customize} />
      {/* <InfoSection {...homeObjTwo} /> */}
      <div id="features"></div>
      <InfoSection {...brandIdentity} />
      <InfoSection {...homeObjFour} />
      <div id="subscribe"></div>
      {/* <PDF /> */}
      <Form />
      <div id="FAQ"></div>
      <FAQ />
      <div id="contactus"></div>
      {/* <InfoSection {...homeObjThree}> */}
      {/* <img  src={logo1} alt="twitter" />
      <img  src={logo2} alt="LinkedIN" /> */}
      {/* </InfoSection> */}

      {/* to do : Fonts in respomsive
      Images  and their animation
      MOBile  buuton
      Image button
      button in mobile menu */}
    </>
  );
}

export default Home;
