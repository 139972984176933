import {
  AboutCard,
  AboutColumn,
  AboutHolder,
  AboutRow,
  AboutSec,
  Heading,
  Img,
  Subtitle,
  TextWrapper,
} from "./Aboutus.elements";

import { Container } from "../../globalStyles";
import React from "react";
import logo from "../../images/magnefier.png";
import logo2 from "../../images/magnefier.png";
import logo3 from "../../images/magnefier.png";

// import { Link } from 'react-router-dom';

function Aboutus({ lightBg, lightText, lightTextDesc, imgStart }) {
  return (
    <>
      <div id="about"></div>

      <AboutSec lightBg={!lightBg} data-aos="fade-up" data-aos-duration="4=00">
        <Container>
          <AboutRow imgStart={imgStart}>
            <AboutColumn>
              <TextWrapper>
                {/* <TopLine data-aos="fade-right" data-aos-duration="2400" lightTopLine={lightTopLine}></TopLine> */}
                <Heading
                  data-aos="fade-right"
                  data-aos-duration="2400"
                  lightText={lightText}
                >
                  About us
                </Heading>
                <Subtitle
                  data-aos="fade-right"
                  data-aos-duration="2400"
                  lightTextDesc={lightTextDesc}
                >
                  At QRFD, we believe that products must be protected from fraud
                  and scammers .{" "}
                </Subtitle>
                <Subtitle
                  data-aos="fade-right"
                  data-aos-duration="2400"
                  lightTextDesc={lightTextDesc}
                >
                  We’re providing total clarity to products listings,
                  eliminating the physical and mental hassle, using technology
                  to assist buyers to easily maintain their products and update
                  them with any new news about their products treating all
                  customers and companies equally. Creating a platform that’s
                  based on the transparency of information and activities.{" "}
                </Subtitle>
                <Subtitle
                  data-aos="fade-right"
                  data-aos-duration="2400"
                  lightTextDesc={lightTextDesc}
                >
                  QRFD is a system where products are protected from any fraud.
                </Subtitle>
                {/* <Button big fontBig primary={primary}>
                    {buttonLabel}
                  </Button> */}
              </TextWrapper>
            </AboutColumn>
            <AboutColumn>
              <AboutHolder>
                <AboutCard data-aos="fade-left" data-aos-duration="500">
                  <Img src={logo} alt="icon" />
                  <h3>Industrial</h3>
                  {/* <p>A variety of units that meet your living requirements.</p> */}
                </AboutCard>
                <AboutCard data-aos="fade-left" data-aos-duration="1000">
                  <Img src={logo2} alt="icon" />
                  <h3>Construction</h3>
                  {/* <p>A variety of units that meet your business needs.</p> */}
                </AboutCard>
                <AboutCard data-aos="fade-left" data-aos-duration="1500">
                  <Img src={logo3} alt="icon" />
                  <h3>Fashion</h3>
                  {/* <p>A variety of units that meet your professional demands.</p> */}
                </AboutCard>
              </AboutHolder>
            </AboutColumn>
          </AboutRow>
        </Container>
      </AboutSec>
    </>
  );
}

export default Aboutus;
