import styled from "styled-components";

export const AboutSec = styled.div`

  color: #ffff;
  padding: 60px 0;
  height: auto;
  background: ${({ lightBg }) => (lightBg ? "#FFFF" : "#101522")};
`;

export const AboutRow = styled.div`
 
  display: flex;
  margin: 100px -15px -15px 25px;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: ${({ imgStart }) => (imgStart ? "row-reverse" : "row")};
`;

export const AboutColumn = styled.div`
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 50%;
  flex-basis: 50%;
 

  @media screen and (max-width: 768px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
  }
`;
export const AboutCard = styled.div`
  color: #000;
  padding: 16px;
  display: flex;
  flex-direction: column;
  width: 50%;
  min-width: 152px;
  //  height: 520px; 
  h3 {
    margin-top: 16px;
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 24px;
  }
  p {
    margin-top: 16px;
    margin: auto;
    display: flex;
  }
  img {
    width: 65px;
  }
`;

export const AboutHolder = styled.div`
  /* max-width: 555px; */
  display: flex;
  align-items: flex-start;
  Height: 752px
  justify-content: space-between;
  flex-wrap: wrap;

  @media screen and (max-width: 700px) {
    padding-bottom: 20px;
    flex-wrap: wrap;
    /* overflow-x: auto ; */
    /* fire fox */
    /* scrollbar-width: thin; */
    /* other */
    /* &::-webkit-scrollbar {
      height: 2px;
    }
    &::-webkit-scrollbar-track {
      background-color: #ddd;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #000;
      border-radius: 10px;
    } */
  }
`;
export const TextWrapper = styled.div`
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;

  @media screen and (max-width: 768px) {
    padding-bottom: 65px;
  }
`;

export const TopLine = styled.div`
  color: ${({ lightTopLine }) => (lightTopLine ? "#a9b3c1" : "#4B59F7")};
  font-size: 18px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  margin-bottom: 16px;
`;

export const Img = styled.img`
  padding-right: 0px;
  padding-left: 10px;
  border: 0;
  border-radius: 30%;
  max-width: 75%;
  vertical-align: middle;
  display: inline-block;
  max-height: 500px;
`;

export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: ${({ lightText }) => (lightText ? "#f7f8fa" : "#000")};
`;

// export const NavBtnLink = styled(LinkRouter)`
//   border-radius: 50px;
//   background: #1d70b8;
//   white-space: nowrap;
//   padding: 10px 22px;
//   color: #010606;
//   font-size: 16px;
//   outline: none;
//   border: none;
//   cursor: pointer;
//   transition: all 0.2s ease-in-out;
//   text-decoration: none;

//   &:hover {
//     transition: all 0.2s ease-in-out;
//     background: #fff;
//     color: #010606;
//   }
// `;
export const Subtitle = styled.p`
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
  color: ${({ lightTextDesc }) => (lightTextDesc ? "#a9b3c1" : "#1c2237")};
`;

export const Spanss = styled.span`
  font-weight: bold;
  grid-row-start: 1;
  grid-column-start: 1;
  font-size: 4rem;
  --stack-height: calc(100% / var(--stacks) - 1px);
  --inverse-index: calc(calc(var(--stacks) - 1) - var(--index));
  --clip-top: calc(var(--stack-height) * var(--index));
  --clip-bottom: calc(var(--stack-height) * var(--inverse-index));
  clip-path: inset(var(--clip-top) 0 var(--clip-bottom) 0);
  animation: stack 340ms cubic-bezier(0.46, 0.29, 0, 1.24) 1 backwards
      calc(var(--index) * 120ms),
    glitch 2s ease infinite 2s alternate-reverse;

  @keyframes stack {
    0% {
      opacity: 0;
      transform: translateX(-50%);
      text-shadow: -2px 3px 0 red, 2px -3px 0 blue;
    }
    60% {
      opacity: 0.5;
      transform: translateX(50%);
    }
    80% {
      transform: none;
      opacity: 1;
      text-shadow: 2px -3px 0 red, -2px 3px 0 blue;
    }
    100% {
      text-shadow: none;
    }
  }

  @keyframes glitch {
    0% {
      text-shadow: -2px 3px 0 red, 2px -3px 0 blue;
      transform: translate(var(--glitch-translate));
    }
    2% {
      text-shadow: 2px -3px 0 red, -2px 3px 0 blue;
    }
    4%,
    100% {
      text-shadow: none;
      transform: none;
    }
  }
`;
