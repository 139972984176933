import { FaBars, FaTimes } from "react-icons/fa";
import {
  MobileIcon,
  Nav,
  NavBtnLink,
  NavItem,
  NavItemBtn,
  NavLinks,
  NavLogo,
  NavMenu,
  NavbarContainer,
} from "./Navbar.elements";
import React, { useEffect, useState } from "react";

import { Button } from "../../globalStyles";
import { IconContext } from "react-icons/lib";
import { PopupButton } from "react-calendly";
import logo from "../../images/Qrfdpng.png";

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener("resize", showButton);

  return (
    <>
      <IconContext.Provider value={{ color: "#000000" }}>
        <Nav>
          <NavbarContainer>
            <NavLogo to="/" onClick={closeMobileMenu}>
              {/* <NavIcon /> */}
              <img src={logo} alt="QRFD logo" />
            </NavLogo>
            <MobileIcon onClick={handleClick}>
              {click ? <FaTimes /> : <FaBars />}
            </MobileIcon>
            <NavMenu onClick={handleClick} click={click}>
            <NavItem>
                <NavLinks to="/" onClick={closeMobileMenu}>
                  Home
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to="/qrfd" onClick={closeMobileMenu}>
                  QRFD
                </NavLinks>
              </NavItem>
              {/* <NavItem>
                <NavLinks to="#about" onClick={closeMobileMenu}>
                  About
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to="#features" onClick={closeMobileMenu}>
                  Features
                </NavLinks>
              </NavItem> */}
             
              <NavItem>
                <NavLinks to="/shop" onClick={closeMobileMenu}>
                  Ecomm
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to="/pricing" onClick={closeMobileMenu}>
                  Pricing
                </NavLinks>
              </NavItem>
              {/* <PopupButton
                url="https://calendly.com/badrmahmoud5@gmail.com"
                rootElement={document.getElementById("root")}
                text="Click here to schedule!"
              /> */}
              <NavItemBtn className="res">
                {button ? (
                  <NavBtnLink  href="https://vcboard.qrfds.com/register" target="_blank" rel="noopener noreferrer">
                    <Button primary>Try Free</Button>
                  </NavBtnLink>
                ) : (
                  <NavBtnLink  href="https://vcboard.qrfds.com/register" target="_blank" rel="noopener noreferrer">
                    <Button onClick={closeMobileMenu} fontBig primary>
                      Try Free
                    </Button>
                  </NavBtnLink>
                )}
              </NavItemBtn>
            </NavMenu>
            <NavMenu className="no_res" onClick={handleClick} click={click}>
              <NavItemBtn>
                {button ? (
                  <NavBtnLink  href="https://vcboard.qrfds.com/register" target="_blank" rel="noopener noreferrer">
                    <Button primary borderColor={"#000"}>
                      Try Free
                    </Button>
                  </NavBtnLink>
                ) : (
                  <NavBtnLink  href="https://vcboard.qrfds.com/register" target="_blank" rel="noopener noreferrer">
                    <Button onClick={closeMobileMenu} fontBig primary>
                      Try Free
                    </Button>
                  </NavBtnLink>
                )}
              </NavItemBtn>
            </NavMenu>
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
}

export default Navbar;
