import React from 'react';
import "./pricing.css"
import { Container } from "../../globalStyles";
function Pricing() {
  return (
    <Container>
    <div>
      <h2 className="text-center etch">Pick the best plan for you</h2>

      <div className="pricing-box-container">
        <div className="pricing-box text-center">
          <h5 className="title">Free</h5>
          <p className="price"><sup>$</sup>0<sub>/mo</sub></p>
          <ul className="features-list">
            <li><strong>1</strong> Website</li>
            <li><strong>1</strong> User</li>
            <li><strong>3</strong> Pages</li>
            <li><strong>5</strong> GB Storage</li>
            <li><strong>1</strong> Domain Connection</li>
            <li><strong>CRM</strong> </li>
            <li><strong>SSL</strong> </li>
            <li><strong></strong> </li>
        
           
          </ul>
          <button className="btn-primary">Get Started</button>
        </div>

        <div className="pricing-box pricing-box-bg-image text-center">
          <h5 className="title">Premium</h5>
          {/* <p className="price"><sup>$</sup>39<sub>/mo</sub></p> */}
          <p className="price-mid">Coming Soon</p>
          <ul className="features-list">
            <li><strong>5</strong> Websites</li>
            <li><strong>5</strong> Admins</li>
            <li><strong>20</strong> Pages</li>
            <li><strong>Full</strong> E-Commerce Solution</li>
            <li><strong>50</strong> GB Storage</li>
            <li><strong>SEO</strong> Optimization</li>
            <li><strong>Email</strong> Sending</li>
            <li><strong>SSL</strong> </li>
          </ul>
          <button className="btn-primary">Get Started</button>
        </div>

        <div className="pricing-box text-center">
          <h5 className="title">Platinum</h5>
          {/* <p className="price"><sup>$</sup>89<sub>/mo</sub></p> */}
          <p className="price">Coming Soon</p>
          <ul className="features-list">
          <li><strong>10</strong> Websites</li>
            <li><strong>10</strong> Admins</li>
            <li><strong>50</strong> Pages</li>
            <li><strong>Full</strong> E-Commerce Solution</li>
            <li><strong>100</strong> GB Storage</li>
            <li><strong>SEO</strong> Optimization</li>
            <li><strong>Email</strong> Sending</li>
            <li><strong>SSL</strong> </li>
          </ul>
          <button className="btn-primary">Get Started</button>
        </div>
      </div>
    </div>
    </Container>
  );
}

export default Pricing;
