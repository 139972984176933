import "./shop.css";

import {
  AboutCard,
  AboutColumn,
  AboutHolder,
  AboutRow,
  AboutSec,
  BtnWrapper,
  MainBtnLink,
  MainTitle,
  NavBtnLink,
  Para,
  SvgWrapper2,
  TextWrapper,
  TopLine,
} from "./Shop.elements";
import { Button, Container } from "../../globalStyles";
import React, { useState } from "react";
import {
  marketing,
  payment,
  productManagment,
  shipping,
  shopInfo,
  storeBuilder,
} from "../HomePage/Data";

import { InfoSection } from "../../components";

import messi from "../../images/messiFull.PNG";

// import { Link } from 'react-router-dom';

const Shop = ({
  lightBg,

}) => {
  const [value, setValue] = useState("Hello World");
  const handleChange = (e) => setValue(e.target.value);
  //const { imgStart, } = shopInfo
  return (
    <>
      <div id="shop"></div>

      <AboutSec lightBg={!lightBg} data-aos="fade-up" data-aos-duration="400">
        <Container>
          <MainTitle>
            <span
              data-aos="fade-down"
              data-aos-offset="200"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
            >
              Start Selling Now
            </span>
          </MainTitle>
          <Para data-aos="fade-up" data-aos-duration="3000">
            Ready to start building your online store? Sign up for our ecommerce
            builder today and get started in minutes. With our easy-to-use tools
            and customizable templates, you'll have a beautiful,
            professional-looking online store up and running in no time.
          </Para>
          <BtnWrapper>
            <MainBtnLink to="#subscribe">
              <Button primary Color borderColor={"#000"}>
                Sign Up Now
              </Button>
            </MainBtnLink>
          </BtnWrapper>
          {/* <AboutRow imgStart={imgStart}> */}
          {/* <a
                href="https://www.mythrillfiction.com/the-dark-rider"
                alt="Mythrill"
                target="_blank"
              >
                <div class="card">
                  <div class="wrapper">
                    <img
                      src="https://shop.app/cdn/shopifycloud/arrive_website/assets/marketing/gravity/home-cash-iphone-small-bf375941a47aa2b79ceff827082e2be6c70d8c6fdf3f7ba662ec70e43aa4c9db.png"
                      class="cover-image"
                    />
                  </div>
                  <img src={logo2} class="title" />
                  <img
                    src="https://ggayane.github.io/css-experiments/cards/dark_rider-character.webp"
                    class="character"
                  />
                </div>
              </a> */}
          <MainTitle>
            <SvgWrapper2>
              <a
                href="https://vcboard.qrfds.com/"
                alt="Mythrill"
                target="_blank"
              >
                <div class="card">
                  <div class="wrapper">
                    <img
                      src="https://shop.app/cdn/shopifycloud/arrive_website/assets/marketing/gravity/home-cash-iphone-small-bf375941a47aa2b79ceff827082e2be6c70d8c6fdf3f7ba662ec70e43aa4c9db.png"
                      class="cover-image"
                    />
                  </div>
                  {/* <img
                    src="https://ggayane.github.io/css-experiments/cards/force_mage-title.png"
                    class="title"
                  /> */}
                  <img src={messi} class="character" />
                </div>
              </a>
            </SvgWrapper2>
          </MainTitle>

          <InfoSection {...storeBuilder} />
          <InfoSection {...marketing} />
          <InfoSection {...productManagment} />
          <InfoSection {...payment} />
          <InfoSection {...shipping} />
          {/* </AboutRow> */}
        </Container>
      </AboutSec>
    </>
  );
};

export default Shop;
