import styled, { keyframes } from "styled-components";

export const play = keyframes`
    0% {
        left: 20%;
        filter: grayscale(1);
    } 25% {
        filter: grayscale(1);
    } 30%{
        left: calc(50% - 110px) ;
        -webkit-transform:translate(-50%, -50%);
        transform:translate(-50%, -50%);
        filter: grayscale(0);
    } 80%{
        left: calc(50% + 110px) ;
        -webkit-transform:translate((-50%), -50%);
        transform:translate((-50%), -50%);
        filter: grayscale(0);
    } 81% {
        filter: grayscale(1);
    } 100%{
        left: 80%;
        -webkit-transform:translate(-50%, -50%);
        transform:translate(-50%, -50%);
        filter: grayscale(1);
    }
`;

export const ProductSec = styled.div`
  color: transparent;
  padding: 60px 0;
  position: relative;
  //background: ${({ lightBg }) => (lightBg ? "#FFFF" : "#101522")};

  img.freeImage {
    position: absolute;
    width: 150px;
    left: 20%;
    top: 50%;
    transform: translateY(-50%);
    filter: grayscale(1);
    animation: ${play} 10s linear alternate infinite;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;
export const Root = styled.div`
  /* max-height: 200px; */
  display: flex;
  justify-content: center;
  img {
    position: absolute;
    width: 100px;
    margin: 50px;
  }

  @media screen and (max-width: 768px) {
    img {
      display: none;
    }
  }
`;
