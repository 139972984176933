import styled, { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'CircularMed', sans-serif;
  /* background: #F5F5F5; */
 } 

/* h2{
  font-family:  'CircularMed',sans-serif;
} */

`;

export const Container = styled.div`
  user-select: auto;
  z-index: 1;
  width: 100%;
  max-width: 1420px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 50px;
  padding-left: 50px;

  position: relative;

  @media screen and (max-width: 991px) {
    padding-right: 30px;
    padding-left: 30px;
  }
`;

export const Button = styled.button`
  border-radius: 24px;
  background: ${({ btnColor, primary }) =>
    btnColor || (primary ? "#fff" : "#000")};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "12px 64px" : "10px 20px")};
  color: ${({ color }) => (color ? "#000" : "#1d6086")};
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  font-weight: bold;
  outline: none;
  border: ${({ borderColor }) =>
    borderColor ? `1px solid ${borderColor}` : "1px solid #E7131A"};
  cursor: pointer;
  text-decoration: none;
  margin-top: 20px;

  &:hover {
    transition: all 0.3s ease-out;
    background-color: ${({ btnColor, primary }) =>
      btnColor || (primary ? "#fff" : "#000")};
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.35);
  }

  @media screen and (max-width: 960px) {
    /* width: 100%; */
  }
`;
export default GlobalStyle;
