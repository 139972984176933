import styled from "styled-components";

export const AboutSec = styled.div`
  color: "#fff";
  padding: 100px 0;
  background-color: white;
  max-width: 1125px;
  left: auto;
  margin: auto;
  /* border-radius: 24px;
  margin-bottom: 90px; */
`;

export const AboutRow = styled.div`
  display: flex;
  margin: -15px -15px -15px -15px;
  flex-wrap: wrap;
  align-items: center;
  /* flex-direction: ${({ imgStart }) => (imgStart ? "row-reverse" : "row")}; */
`;

export const AboutColumn = styled.div`
  max-width: 100%;
  flex-basis: 100%;
  display: flex;
  justify-content: center;

  /* flex-basis: 50%; */

  @media screen and (max-width: 768px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
  }
`;
export const Form = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 200px;

  @media screen and (max-width: 820px) {
    flex-direction: column;
    width: 80%;
  }
`;

export const FormInput = styled.input`
  padding: 10px 20px;
  border-radius: 2px;
  margin-right: 24px;
  outline: none;
  border: none;
  font-size: 16px;
  border: 1px solid #fff;

  &::placeholder {
    color: #242424;
  }

  @media screen and (max-width: 820px) {
    width: 100%;
    margin: 0 0 16px 0;
  }
`;

export const AboutHolder = styled.div`
  /* max-width: 555px; */
  display: flex;
  align-items: flex-end;

  justify-content: space-between;
  flex-wrap: wrap;
`;
export const TextWrapper = styled.div`
  max-width: 840px;
  padding-top: 0;
  padding-bottom: 48px;
  justify-content: center;

  @media screen and (max-width: 768px) {
    padding-bottom: 65px;
  }
`;

export const AboutFlexRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  /* width: 50%; */
`;

export const Question = styled.div`
  display: inline-flex;
  justify-content: space-around;
  color: black;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 1.4;
  margin-bottom: 16px;
  /* max-width: 552px; */
  width: 100%;
  box-sizing: border-box;
  padding: 10px;

  @media only screen and (max-width: 700px) {
    width: 100%;
  }

  /* opacity: 75%; */
`;

export const Img = styled.img`
  padding-right: 0;
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  max-height: 500px;
`;

export const Heading = styled.h1`
  display: flex;
  /* text-align: center; */
  justify-content: center;
  margin-bottom: 24px;
  font-size: 35px;
  line-height: 27px;
  font-weight: 600;
  color: ${({ lightText }) => (lightText ? "#1d6086" : "#000")};
  span {
    color: #1d6086;
    margin-right: 8px;
  }
`;

export const Subtitle = styled.p`
  text-align: center;
  max-width: 740px;
  /* margin-bottom: 35px; */
  font-size: 16px;
  line-height: 24px;
  opacity: 85%;
  color: ${({ lightTextDesc }) => (lightTextDesc ? "#1d6086" : "#1d6086")};

  @media screen and (max-width: 768px) {
    text-align: left;
  }
`;
