import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    const offsets = {
      contactus: 150,
      about: 0,
      features: 0,
      subscribe: 0,
      FAQ: 20,
    };
    if (location.hash === "") return window.scroll(0, 0);
    const id = location.hash.replace("#", "");

    const scrollPosition =
      document.getElementById(id)?.getBoundingClientRect()?.y || 0;
    const bodyPosition = Math.abs(
      document.body?.getBoundingClientRect().y || 0
    );
    const offset =
      window.innerWidth <= 700 ? (offsets[id] || 0) + 70 : offsets[id] || 0;
    window.scroll({
      // behavior: 'smooth',
      top: bodyPosition - 80 + scrollPosition + offset,
    });
  }, [location]);

  return null;
}
