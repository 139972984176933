import "./Form.css";

import {
  AboutColumn,
  AboutRow,
  AboutSec,
  Form,
  FormInput,
  FormWrapper,
  Heading,
  Subtitle,
  TextWrapper,
  TextWrapper2,
  TopLine,
} from "./PDF.elements";
import { Button, Container } from "../../globalStyles";
import React, { useState } from "react";

import Validate from "./ValidateInfo";
import { sendMail } from "./Mail";
import useForm from "./useform";

// import { Link } from 'react-router-dom';

// import logo from '../../images/Groupi1.svg';
// import logo2 from '../../images/Groupi2.svg';
// import logo3 from '../../images/Groupi3.svg';

const PDF = ({
  lightBg,
  lightTopLine,
  lightText,
  lightTextDesc,
  imgStart,
  submitForm,
}) => {
  const { handleChange, handleSubmit, values, errors } = useForm(
    submitForm,
    Validate
  );

  // const [errors, setErrors] = useState({});

  // const [info, setInfo] = useState({
  //   mail: "",
  //   phone: "",
  //   name: "",
  //   company_name: ""
  // })

  // const handleInput = (e, field) => {

  //   setInfo({ ...info, [field]: e.target.value });
  // }
  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   setErrors(Validate(info));
  //   // setIsSubmitting(true);
  // };
  return (
    <>
      <AboutSec data-aos="fade-up" data-aos-duration="2400" lightBg={lightBg}>
        <Container>
          <AboutRow
            data-aos="fade-up"
            data-aos-duration="2400"
            imgStart={imgStart}
          >
            <AboutColumn>
              <TextWrapper>
                <TextWrapper2>
                  <TopLine lightTopLine={!lightTopLine}>
                    How does it work?
                  </TopLine>
                  <Heading lightText={!lightText}>Request a demo</Heading>
                  <Subtitle lightTextDesc={!lightTextDesc}>
                    Prevent your products from getting copied by requesting QRFD
                    system.{" "}
                  </Subtitle>
                </TextWrapper2>

                <Form onSubmit={handleSubmit} noValidate>
                  <FormWrapper>
                    <FormInput
                      borderco={errors.username}
                      name="username"
                      type="text"
                      placeholder="Your Name"
                      pattern=""
                      value={values.username}
                      onChange={handleChange}
                      // required
                    />
                    {errors.username && <p>{errors.username}</p>}
                  </FormWrapper>
                  {/* <FormWrapper>
                  <FormInput
                    borderco = {errors.company_name}     
                    name='company_name'
                    type='text'
                    placeholder='Company Name'
                    pattern=""
                    value={values.company_name } 
                    onChange={handleChange}
                    // required
                     />
                     {errors.company_name && <p>{errors.company_name}</p>}
                     </FormWrapper> */}

                  <FormWrapper>
                    <FormInput
                      borderco={errors.email}
                      value={values.email}
                      onChange={handleChange}
                      name="email"
                      type="email"
                      placeholder="Add Your Email"
                      pattern="/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/"
                      // required
                    />

                    {errors.email && <p>{errors.email}</p>}
                  </FormWrapper>
                  <FormWrapper>
                    <FormInput
                      borderco={errors.phone}
                      value={values.phone}
                      onChange={handleChange}
                      name="phone"
                      type="phone"
                      placeholder="Phone number"
                      pattern=""
                      // required
                    />
                    {errors.phone && <p>{errors.phone}</p>}
                  </FormWrapper>
                  {/* <AboutColumn> */}
                  <Button
                    big
                    onClick={(e) => {
                      handleChange(e);
                    }}
                    primary
                    Color
                    borderColor={"#000"}
                    type="submit"
                  >
                    Request a demo
                  </Button>
                  {/* </AboutColumn> */}
                </Form>

                {/* <Button big fontBig primary={primary}>
                    {buttonLabel}
                  </Button> */}
              </TextWrapper>
            </AboutColumn>
          </AboutRow>
        </Container>
      </AboutSec>
    </>
  );
};
// onClick={(e) => handleSubmit(e), sendMail(e, info.mail, info.phone, info.name, info.Company_name)}
export default PDF;
