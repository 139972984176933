import "react-accessible-accordion/dist/fancy-example.css";
import "./accordion.css";

import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";

import React from "react";

// Demo styles, see 'Styles' section below for some notes on use.

export default function FaqAccordion() {
  const items = [
    {
      id: 1,
      heading: "How does it work?",
      content:
        "You could request a demo and we will contact you with in 5 working days to show you how to connect your data with the Qrfd system.",
    },
    {
      id: 2,
      heading: "what do clients see when they view my products?",
      content:
        "A dedicated configuiration system for each company to help them add all of their products details, social media and preferred languages assist their clients to verify the purchased product and make sure that it is original.",
    },

    {
      id: 3,
      heading: "How do buisnesses make payments?",
      content: "Buisnesses can reserve and pay through the gateways of Paymob.",
    },
    {
      id: 4,
      heading: "What does a company's verify page consist of?",
      content:
        "The look and feel of the Company's brand. Listing products and their types with all the details and features needed. A connection between potential clients and the buisness.",
    },
    {
      id: 5,
      heading: "Is there real-time analytics?",
      content:
        "Yes, we offer our product owners a dashboard for viewing all products insights, employees data  and real-time analytics",
    },

    {
      id: 6,
      heading: "what languages do you offer?",
      content: "we currently support English, German and Arabic.",
    },
  ];
  return (
    <Accordion allowZeroExpanded>
      {items.map((item) => (
        <AccordionItem key={item.id}>
          <AccordionItemHeading>
            <AccordionItemButton>{item.heading}</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>{item.content}</AccordionItemPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );
}
