import "./wave.scss";
import Clients from "./Clients";
import React, { useEffect, useState } from "react";
import { Button, Container } from "../../globalStyles";
import { MainBtnLink } from "./../MainPage/MainPage.elements";
import { Link } from 'react-router-dom';
import {
  FaShoppingBag,
  FaIdCard,
  FaGlobeEurope,
  FaGlobe,
  FaArrowRight,
  FaArrowDown
} from 'react-icons/fa';
import {

  SocialIconLink,

} from "./../Footer/Footer.elements";
function FeaturesVc() {
  const [text, setText] = useState('Fastest');
  const words = ['Ecommerce', 'Website', 'Virtual card'];
  let currentIndex = 0;

  useEffect(() => {
    const interval = setInterval(() => {
      setText(words[currentIndex]);
      currentIndex = (currentIndex + 1) % words.length;
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Container>
      <div id="vc"></div>
      <div className="Vc">
        <div className="text-container">{text}</div>
        {/* <FaArrowDown className="arrow"> Get Started</FaArrowDown> */}
      </div>

      {/* // <div className="social-icons-container"> */}

      {/* <Link to="/">
       <FaShoppingBag  data-aos="zoom-out-right" data-aos-duration="2600"/>
      </Link>
      <Link to="/">
       <FaGlobe data-aos="zoom-in-up" data-aos-duration="2600"/>
      </Link>
      <Link to="/">
       <FaIdCard data-aos="zoom-out-left" data-aos-duration="2600" />
      </Link> */}
      <MainBtnLink href="https://vcboard.qrfds.com/register" target="_blank" rel="noopener noreferrer">
        <Button fontBig primary borderColor={"#000"}>
          Get Started
        </Button>
      </MainBtnLink>

      {/* </div>
      */}
    </Container>
  );
}

export default FeaturesVc;
