import { Link } from "react-router-dom";
import styled from "styled-components";

export const BtnWrapper = styled.div`
  width: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 20px;
`;

export const MainBtnLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 8px 16px;
  /* padding-left: 200px; */
  height: 100%;
  width: 100%;
`;
//--------------------------------------------------------

export const MainTitle = styled.h2`
  display: flex;
  position: relative;

  font-size: 60px;
  font-weight: bold;

  margin-top: 10px;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 84%;

  margin: auto;
  margin-bottom: 50px;
  padding: 10px;
  line-height: 1.1;

  span {
    // background: linear-gradient(to right, #f32170, #ff6b08, #cf23cf, #eedd44);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-image: rgb(29, 96, 134);
    background-image: linear-gradient(
      90deg,
      rgba(29, 96, 134, 1) 3%,
      rgba(33, 222, 237, 1) 100%
    );
    position: relative;
  }
  @media only screen and (max-width: 700px) {
    font-size: 30px;
    margin-bottom: 100px;
    font-weight: bolder;
    width: 100%;
    align-items: center;
    max-width: none;
  }
`;

export const SvgWrapper2 = styled.div`
  // width: 100px;
  /* position: absolute; */

  display: flex;
  left: 0px;
  top: 0px;
  z-index: 0;

  transform: translate(-100%, 10%);

  img {
    width: 100%;
  }
`;
export const Para = styled.p`
  position: relative;
  display: flex;
  margin: 20px 10%;
  padding: 20px;
  border: 0;
  max-width: 45%;
  margin: auto;
  /* vertical-align: baseline; */
  color: black;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* font-family:'Lato', sans-serif;  */
  opacity: 0.54;
  text-align: center;
  @media only screen and (max-width: 700px) {
    width: 100%;
    align-items: center;
    max-width: none;
  }
`;

export const AboutSec = styled.div`
  color: #ffff;
  padding: 60px 0;
  height: auto;
  background: ${({ lightBg }) => (lightBg ? "#FFFF" : "#101522")};
`;

export const AboutRow = styled.div`
  display: flex;
  margin: 0 -15px -15px 25px;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: ${({ imgStart }) => (imgStart ? "row-reverse" : "row")};
`;

export const AboutColumn = styled.div`
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 50%;
  flex-basis: 50%;

  @media screen and (max-width: 768px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
  }
`;
export const AboutCard = styled.div`
  color: #000;
  padding: 16px;
  display: flex;
  flex-direction: column;
  width: 33.33%;
  min-width: 152px;
  /* height: 220px; */
  h3 {
    margin-top: 16px;
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 24px;
  }
  p {
    margin-top: 16px;
    margin: auto;
    display: flex;
  }
  img {
    width: 65px;
  }
`;

export const AboutHolder = styled.div`
  /* max-width: 555px; */
  display: flex;
  align-items: flex-start;

  justify-content: space-between;
  flex-wrap: wrap;

  @media screen and (max-width: 700px) {
    padding-bottom: 20px;
    flex-wrap: wrap;
    /* overflow-x: auto ; */
    /* fire fox */
    /* scrollbar-width: thin; */
    /* other */
    /* &::-webkit-scrollbar {
      height: 2px;
    }
    &::-webkit-scrollbar-track {
      background-color: #ddd;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #000;
      border-radius: 10px;
    } */
  }
`;
export const TextWrapper = styled.div`
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;

  @media screen and (max-width: 768px) {
    padding-bottom: 65px;
  }
`;

export const TopLine = styled.div`
  color: ${({ lightTopLine }) => (lightTopLine ? "#a9b3c1" : "#4B59F7")};
  font-size: 18px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  margin-bottom: 16px;
`;

export const Img = styled.img`
  padding-right: 0px;
  padding-left: 10px;
  border: 0;
  //border-radius: 30%;
  max-width: 110%;
  vertical-align: middle;
  display: inline-block;
  max-height: 500px;
`;

export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: ${({ lightText }) => (lightText ? "#f7f8fa" : "#000")};
  span {
    color: #1d6086;
  }
`;

// export const NavBtnLink = styled(LinkRouter)`
//   border-radius: 50px;
//   background: #1d70b8;
//   white-space: nowrap;
//   padding: 10px 22px;
//   color: #010606;
//   font-size: 16px;
//   outline: none;
//   border: none;
//   cursor: pointer;
//   transition: all 0.2s ease-in-out;
//   text-decoration: none;

//   &:hover {
//     transition: all 0.2s ease-in-out;
//     background: #fff;
//     color: #010606;
//   }
// `;

export const Subtitle = styled.p`
  ::selection {
    background-color: red !important;
    color: white;
  }
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
  color: ${({ lightTextDesc }) => (lightTextDesc ? "#a9b3c1" : "#1c2237")};
`;

export const Spanss = styled.span`
  font-weight: bold;
  grid-row-start: 1;
  grid-column-start: 1;
  font-size: 4rem;
  --stack-height: calc(100% / var(--stacks) - 1px);
  --inverse-index: calc(calc(var(--stacks) - 1) - var(--index));
  --clip-top: calc(var(--stack-height) * var(--index));
  --clip-bottom: calc(var(--stack-height) * var(--inverse-index));
  clip-path: inset(var(--clip-top) 0 var(--clip-bottom) 0);
  animation: stack 340ms cubic-bezier(0.46, 0.29, 0, 1.24) 1 backwards
      calc(var(--index) * 120ms),
    glitch 2s ease infinite 2s alternate-reverse;

  @keyframes stack {
    0% {
      opacity: 0;
      transform: translateX(-50%);
      text-shadow: -2px 3px 0 red, 2px -3px 0 blue;
    }
    60% {
      opacity: 0.5;
      transform: translateX(50%);
    }
    80% {
      transform: none;
      opacity: 1;
      text-shadow: 2px -3px 0 red, -2px 3px 0 blue;
    }
    100% {
      text-shadow: none;
    }
  }

  @keyframes glitch {
    0% {
      text-shadow: -2px 3px 0 red, 2px -3px 0 blue;
      transform: translate(var(--glitch-translate));
    }
    2% {
      text-shadow: 2px -3px 0 red, -2px 3px 0 blue;
    }
    4%,
    100% {
      text-shadow: none;
      transform: none;
    }
  }
`;

export const InfoSec = styled.div`
  color: #fff;
  padding: 160px 0;
  background: ${({ lightBg }) => (lightBg ? "#FAFAFA" : "#101522")};
`;

export const InfoRow = styled.div`
  display: flex;
  margin: 120px -15px 60px -15px;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: ${({ imgStart }) => (imgStart ? "row-reverse" : "row")};
`;

export const InfoColumn = styled.div`
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 50%;
  flex-basis: 50%;

  @media screen and (max-width: 768px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
  }
`;

export const ImgWrapper = styled.div`
  max-width: 555px;
  display: flex;
  justify-content: ${({ start }) => (start ? "center" : "flex-end")};
`;
