//Search your unit
export const homeObj = {
  primary: true,
  lightBg: true,
  lightTopLine: false,
  lightText: false,
  lightTextDesc: false,
  // topLine: 'Eshtri',
  headline: " QR codes for each product ",
  headline1: "Configure",
  description:
    "Search between all your products, create, add, update and delete your product's QR codes.",
  buttonLabel: "Get Started",
  imgStart: "start",
  img: require("../../images/QRlap.png"),
  // logo: require("../../images/magnefier.png"),
  alt: "Credit Card",
  start: "",
  disp_icons: true,
  Width: false,
};

//-------------------------------------------------------
export const shopInfo = {
  primary: true,
  lightBg: true,
  lightTopLine: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Store Builder",
  headline: " Your Store Your Way",
  headline1: "Build",
  description:
    "The store builder is the heart of Tex. With a simple drag-and-drop interface, you can design and customize your online store to match your brand and style. Choose from a wide range of templates and themes, or create your own unique design from scratch.",
  buttonLabel: "Get Started",
  imgStart: "",
  img: "https://eep.io/images/yzco4xsimv0y/433SRjV7TLAaSJqKyzNoAB/dc0812aa5b7a3656232d01f7b5228ecd/LandingPages-Desktop-01-Hero.png?w=1520&fm=webp&q=70",
  // logo: require("../../images/magnefier.png"),
  alt: "Credit Card",
  start: true,
  disp_icons: true,
  Pic_Width: "100%",
  media_width: "140%",
};

export const storeBuilder = {
  primary: true,
  lightBg: true,
  lightTopLine: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Store Builder",
  headline: " Your Store Your Way",
  headline1: "Build",
  description:
    "The store builder is the heart of Tex. With a simple drag-and-drop interface, you can design and customize your online store to match your brand and style. Choose from a wide range of templates and themes, or create your own unique design from scratch.",
  buttonLabel: "Get Started",
  imgStart: "y",
  img:  require("../../images/Ecomm1.jfif"),
  // logo: require("../../images/magnefier.png"),
  alt: "Credit Card",
  start: true,
  disp_icons: true,
  //Pic_Width: "100%",
  media_width: "140%",
 
};

export const productManagment = {
  primary: true,
  lightBg: true,
  lightTopLine: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Product Management",
  headline: " Your Products Like a Pro",
  headline1: "Manage",
  description:
    "Tex makes it easy to manage your products and inventory. You can upload product images, descriptions, and pricing information, and organize your products into categories and collections. With real-time inventory tracking, you can easily keep track of your stock levels and avoid overselling.",
  buttonLabel: "Get Started",
  imgStart: "y",
  img: require("../../images/Ecomm2.jfif"),
  // logo: require("../../images/magnefier.png"),
  alt: "Credit Card",
  start: true,
  disp_icons: true,
  media_width: "140%",
};

export const payment = {
  primary: true,
  lightBg: true,
  lightTopLine: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Payment Processing",
  headline: " Payments Online or COD",
  headline1: "Accept",
  description:
    "TIX integrates with a wide range of payment providers, including PayPal, Stripe, and Square. This means you can accept payments from customers around the world, and offer a range of payment options to suit their needs.",
  buttonLabel: "Get Started",
  imgStart: "",
  img:  require("../../images/Ecomm4.jfif"),
  // logo: require("../../images/magnefier.png"),
  alt: "Credit Card",
  start: true,
  disp_icons: true,
  Pic_Width: "100%",
  media_width: "140%",
};

export const shipping = {
  primary: true,
  lightBg: true,
  lightTopLine: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Shipping and Fulfillment",
  headline: " Orders with Ease",
  headline1: "Fulfill",
  description:
    "TIX also includes tools to help you manage shipping and fulfillment. You can set up shipping rates based on weight, destination, or product type, and offer free shipping promotions to your customers. You can also manage order fulfillment from within the TIX platform, or integrate with third-party fulfillment services.",
  buttonLabel: "Get Started",
  imgStart: "y",
  img:  require("../../images/Ecomm3.jfif"),
  // logo: require("../../images/magnefier.png"),
  alt: "Credit Card",
  start: true,
  disp_icons: true,
  // Pic_Width: "100%",
  media_width: "140%",
};

export const marketing = {
  primary: true,
  lightBg: true,
  lightTopLine: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Marketing and Analytics",
  headline: " Your Store and Track Your Success",
  headline1: "Promote",
  description:
    "Tex includes a range of marketing and analytics tools to help you promote your online store and track your performance. You can create promotional campaigns, send email newsletters to your customers, and track your store's traffic and sales using built-in analytics tools.",
  buttonLabel: "Get Started",
  imgStart: "",
  img: require("../../images/Ecomm5.jfif"),
  // logo: require("../../images/magnefier.png"),
  alt: "Credit Card",
  start: true,
  disp_icons: true,
  Pic_Width: "100%",
  media_width: "140%",
};

//---------------------------------------------------------
export const virtualCardInfo = {
  primary: true,
  lightBg: true,
  lightTopLine: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Elevate Your Sales",
  headline: "Online Selling",
  headline1: "Effortless ",
  description:
    " TIX supercharges your website into an online store. Manage inventory, accept secure payments, and create custom product listings effortlessly.",
  buttonLabel: "Get Started",
  imgStart: "",
  img: require("../../images/EcommerceInfo.jpg"),
  // logo: require("../../images/magnefier.png"),
  alt: "Credit Card",
  start: "",
  disp_icons: true,
  Pic_Width: "100%",
  media_width: "140%",
};

export const virtualCardInfo1 = {
  primary: true,
  lightBg: true,
  lightTopLine: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Craft Your Web Presence",
  headline: "Website Creation",
  headline1: "Instant ",
  description:
    "TIX's website builder lets you create a stunning online presence without any coding. Design templates and make your website shine..",
  buttonLabel: "Get Started",
  imgStart: true,
  img: require("../../images/messiFull.PNG"),
  // logo: require("../../images/magnefier.png"),
  alt: "Credit Card",
  start: "",
  disp_icons: true,
  Pic_Width: "40%",
  media_width: "140%",
};

export const virtualCardInfo2 = {
  primary: true,
  lightBg: true,
  lightTopLine: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Your Online Presence",
  headline: "Cards",
  headline1: "Virtual ",
  description:
    "Generate Your virtual card in seconds with all your social media, contacts, mail and much more .",
  buttonLabel: "Get Started",
  imgStart: false,
  img: require("../../images/VcardNFC.png"),
  // logo: require("../../images/magnefier.png"),
  alt: "Credit Card",
  start: "",
  disp_icons: true,
  Pic_Width: "100%",
  media_width: "140%",
};

//Compare between units
export const homeObjOne = {
  primary: true,
  lightBg: true,
  lightTopLine: false,
  lightText: false,
  lightTextDesc: false,
  // topLine: 'Eshtri',
  headline: " growth",
  headline1: "Marketing",

  description:
    "Get direct contact with the merchants, customers and  consumers.",
  buttonLabel: "Get Started",
  imgStart: "",
  img: require("../../images/marketing.jpg"),
  // logo: require("../../images/Booking.svg"),
  alt: "Credit Card",
  start: "",
  disp_icons: true,
  chatbot: "314px", //unit features
};

export const alerting = {
  primary: true,
  lightBg: true,
  lightTopLine: false,
  lightText: false,
  lightTextDesc: false,
  // topLine: 'Eshtri',
  headline: "system",
  headline1: "Alerting ",

  description: "Get alerts instantly if their are any suspesious acts.",
  buttonLabel: "Get Started",
  imgStart: "start",
  img: require("../../images/Phone-Alert.png"),
  // logo: require("../../images/Booking.svg"),
  alt: "Credit Card",
  start: "",
  disp_icons: true,
  chatbot: "314px", //unit features
};

export const customize = {
  primary: true,
  lightBg: true,
  lightTopLine: false,
  lightText: false,
  lightTextDesc: false,
  // topLine: 'Eshtri',
  headline: "your system",
  headline1: "Customize ",

  description: "Customize your system anyway you like",
  buttonLabel: "Get Started",
  imgStart: "",
  img: require("../../images/customization.png"),
  // logo: require("../../images/Booking.svg"),
  alt: "Credit Card",
  start: "",
  disp_icons: true,
  chatbot: "314px", //unit features
};

export const brandIdentity = {
  primary: true,
  lightBg: true,
  lightTopLine: false,
  lightText: false,
  lightTextDesc: false,
  // topLine: 'Eshtri',
  headline: "Identity",
  headline1: "Brand ",

  description: "demonstrate your image to your clients ",
  buttonLabel: "Get Started",
  imgStart: "start",
  img: require("../../images/brandIdentity.jpg"),
  // logo: require("../../images/Booking.svg"),
  alt: "Credit Card",
  start: "",
  disp_icons: true,
  chatbot: "314px", //unit features
};
//Reserve And Pay
// export const homeObjTwo = {
//   primary: true,
//   lightBg: true,
//   lightTopLine: false,
//   lightText: false,
//   lightTextDesc: false,
//   // topLine: 'Eshtri',
//   headline: " A Plan",
//   headline1: "Choose",
//   description:
//     "Subscribe through the website. No commission fee, No commitment, No long-term contracts. Your payment is safe and secure and you can cancel at any time.",
//   buttonLabel: "Learn More",
//   imgStart: "start",
//   // img: require('../../images/19.png'),
//   Compare: true,
//   alt: "Vault",
//   start: "",
//   extraRelatedPics: [
//     {
//       key: 1,
//       src: require("../../images/pricingCards1.png"), //terms and condition
//       alt: "chatExtra5",
//       className: "chatExtra5",
//       anim: {
//         name: "fade-up",
//         ease: "ease-in-sine",
//         duration: "500",
//         // delay: "1800"
//       },
//     },
//     {
//       key: 2,
//       src: require("../../images/pricingCards2.png"), //thanks for trusting us
//       alt: "chatExtra6",
//       className: "chatExtra6",
//       anim: {
//         name: "fade-up",
//         ease: "ease-in-sine",
//         duration: "2600",
//         delay: "900",
//       },
//     },
//     {
//       key: 3,
//       src: require("../../images/pricingCards3.png"), // personal info
//       alt: "chatExtra7",
//       className: "chatExtra7",
//       anim: {
//         name: "fade-up",
//         ease: "ease-in-sine",
//         duration: "1600",
//         // delay: "600"
//       },
//     },
//     // {
//     //   key: 4,
//     //   src: require("../../images/grp2.jpg"), //users info
//     //   alt: "chatExtra8",
//     //   className: "chatExtra8",
//     //   anim: {
//     //     name: "fade-up",
//     //     ease: "ease-in-sine",
//     //     duration: "1000",
//     //     // delay: "100"
//     //   },
//     // },
//   ],
// };

//ChatBot
export const homeObjFour = {
  primary: true,
  lightBg: true,
  lightTopLine: false,
  lightText: false,
  lightTextDesc: false,
  // topLine: 'Eshtri',
  headline: " Insights",
  headline1: "Data",

  description:
    "Connect with our data analytics tools to be updated with your sales and market.",
  buttonLabel: "Learn More",
  imgStart: "",
  img: require("../../images/analytics.png"),
  alt: "Vault",
  start: "yes",
  // logo: require("../../images/Chatbot.svg"),
  disp_icons: true,
  // Width: true,
  chatbot: "314px",
  chatbotM: "290px",
  Pic_Width: "100%",
  // extraRelatedPics: [
  //   {
  //     key: 1,
  //     src: require("../../images/residential.png"),
  //     alt: "chatExtra1",
  //     className: "chatExtra1",
  //     anim: {
  //       name: "zoom-in-left",
  //       ease: "",
  //       duration: "1000",
  //       delay: "1800",
  //     },
  //   },
  //   {
  //     key: 2,
  //     src: require("../../images/location.png"),
  //     alt: "chatExtra2",
  //     className: "chatExtra2",
  //     anim: {
  //       name: "zoom-in-right",
  //       ease: "ease-in-sine",
  //       duration: "1600",
  //       delay: "1500",
  //     },
  //   },
  //   {
  //     key: 3,
  //     src: require("../../images/welcome.png"),
  //     alt: "chatExtra3",
  //     className: "chatExtra3",
  //     anim: {
  //       name: "zoom-in-right",
  //       ease: "ease-in-sine",
  //       duration: "1600",
  //       delay: "600",
  //     },
  //   },
  //   {
  //     key: 4,
  //     src: require("../../images/start.png"),
  //     alt: "chatExtra4",
  //     className: "chatExtra4",
  //     anim: {
  //       name: "zoom-in-left",
  //       ease: "ease-in-sine",
  //       duration: "1600",
  //       delay: "100",
  //     },
  //   },
  // ],
};

//Contact
export const homeObjThree = {
  primary: true,
  lightBg: true,
  lightTopLine: false,
  lightText: false,
  lightTextDesc: false,
  // topLine: 'Eshtri',
  headline: " our journey ",
  headline1: "Let's start",
  description: "Follow us at our pages",
  buttonLabel: "Learn More",
  imgStart: "start",
  // img: require("../../images/Mobile_facebook.png"),
  // logo1: require("../../images/Facebook.svg"),
  // logo2: require("../../images/Instagram.svg"),

  alt: "Vault",
  start: "",
  disp_socials: true,
  Pic_Width: "80%",
  // chatbotM:'290px'
};
